import './bootstrap.js';
import './Components/readbar-bar.js';
import "../css/app.css";

function setDynamicViewportHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--dvh', `${vh}px`);
}

window.addEventListener('resize', setDynamicViewportHeight);
setDynamicViewportHeight();
